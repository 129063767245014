var form = {

	formRemoveError: function () {
		$('.form').find('input, textarea').on('focus', function () {
			$(this).removeClass('parsley-error');
			$(this).next('ul').remove();
		});
	},

	captcha(callback, obj) {
		captchaModule.then((module) => {
			let captcha = new module();
			captcha.initialize(callback, obj);
		});
	},
	event: function () {
		let self = this,
			arrayFormsWithCaptcha = ['formContact', 'formContactAgency', 'formPresse']
		//, 'formInscriptionEvent', 'formEvenements', ], ==> non trouvé : supprimer méthodes
		obj = {
			'formContact': () => {
				self.formContact()
			},
			'formContactAgency': () => {
				self.formContactAgency()
			},
			'formInscriptionEvent': () => {
				self.formInscriptionEvent()
			},
			'formEvenements': () => {
				self.formEvenements()
			},
			'formPresse': () => {
				self.formPresse()
			},
		}
		document.body.addEventListener('submit', (e) => {
			e.preventDefault();

			if (arrayFormsWithCaptcha.includes(e.target.id)) {
				self.captcha(obj[e.target.id],{
					id: e.target.id
				})
			}
		})

		this.formRemoveError();
	},

	formContact: function () {

		var myForm = $('#formContact');
		var token = $('input[name="__RequestVerificationToken"]', myForm).val();

		if (myForm.parsley().isValid()) {
			/*
			var urlSetAbonnementNewsletter = agenceConfig.urlSetAbonnementNewsletter;
			$.ajax({
				url: urlSetAbonnementNewsletter,
				data: {
					__RequestVerificationToken: token,
					codePostal: myForm.find("#CodePostal").val(),
					pays: myForm.find("#Pays").val(),
					email: myForm.find("#Email").val()
				},
				type: "POST",
				async: true
			});*/
			var urlSetContact = agenceConfig.urlSetContact;

			let data = {
				__RequestVerificationToken: token,
				civilite: myForm.find("#Civilite").val(),
				nom: myForm.find("#Nom").val(),
				prenom: myForm.find("#Prenom").val(),
				adresse1: "",
				adresse2: "",
				adresse3: "",
				adresse4: "",
				codePostal: myForm.find("#CodePostal").val(),
				ville: myForm.find("#Ville").val(),
				pays: myForm.find("#Pays").val(),
				email: myForm.find("#Email").val(),
				telephonePortable: myForm.find("#TelMobile").val(),
				telephonePerso: "", //myForm.find("#TelFixe").val()
				codeIsoForMobile: myForm.find("#CodeIsoForMobile").val(),
				codeIsoForFixe: "", //myForm.find("#CodeIsoForFixe").val()
				sujet: "",
				message: myForm.find("#message").val(),
				to: "",
				listCheck: ""
			}

			$.ajax({
				url: urlSetContact,
				data: data,
				type: "POST",
				success: function (data) {
					myForm.find(".form__message_error").html('');
					myForm.find(".form__message_success").html(data.Message);
					setTimeout(function () {
						$('#modalContact').find('.modal').removeClass('modal_open');
						$('html').css('overflow', 'auto');
					}, 3000);

				},
				error: function (data) {
					myForm.find(".form__message_success").html('');
					myForm.find(".form__message_error").html(data.MessageError);
				}

				// success: function (data) {
				//  if (data.MessageError != null && data.MessageError != "") {
				//      myForm.find(".form__message_error").html(j.MessageError);
				//      myForm.find(".form__message_success").html('');

				//  } else {
				//      myForm.find(".form__message_error").html('');
				//      myForm.find(".form__message_success").html('Nous vous remercions pour votre demande.<br>Nous vous adresserons une réponse dans les meilleurs délais.</p><p>Merci de votre confiance.</p><p>L’équipe Terres d\'Aventure</p>');
				//  }
				// },
				// error: function (data) {
				//  myForm.find(".form__message_error").html("Une erreur est survenu, l'envoi de votre demande n'a pas pu aboutir");
				//  myForm.find(".form__message_success").html('');
				// }
			});
		}
	},

	formContactAgency: function () {

		var myForm = $('#formContactAgency');

		var myData = {
			urlForm: myForm.attr('data-url-action'),
			civilite: myForm.find("#Civilite"),
			nom: myForm.find("#Contact_Coordonnees_Nom"),
			prenom: myForm.find("#Contact_Coordonnees_Prenom"),
			email: myForm.find("#Contact_Coordonnees_Email"),
			phone: myForm.find("#Contact_Coordonnees_Phone"),
			message: myForm.find("#Contact_Coordonnees_Message"),
			idAgence: parseInt(agenceConfig.idAgence)
		};

		var token = $('input[name="__RequestVerificationToken"]', myForm).val();

		if (myForm.parsley().isValid()) {

			$.ajax({
				url: myData.urlForm,
				dataType: 'json',
				type: "POST",
				data: {
					__RequestVerificationToken: token,
					civilite: myData.civilite.val(),
					nom: myData.nom.val(),
					prenom: myData.prenom.val(),
					email: myData.email.val(),
					phone: myData.phone.val(),
					message: myData.message.val(),
					idAgence: myData.idAgence
				},
				success: function (data) {
					myForm.find(".form__message_error").html('');
					myForm.find(".form__message_success").html(data.Message);
				},
				error: function (data) {
					myForm.find(".form__message_success").html('');
					myForm.find(".form__message_error").html(data.MessageError);
				},
				complete: function (data) {
					setTimeout(function () {
						$('.form__message').html('');
					}, 3000);
				}
			});

		}
	},

	formInscriptionEvent: function () {

		var myForm = $('#formInscriptionEvent');

		$('#Ereur_Email').html("");
		var erreurMail = "";

		if (!verifMail($('#Inscription_Coordonnees_Email').val())) {

			$('#Ereur_Email').html("l'email est invalide");
			erreurMail = "ok";
		}

		var token = $('input[name="__RequestVerificationToken"]', myForm).val();
		var myData = {
			civilite: myForm.find("#Inscription_Coordonnees_Civilite"),
			nom: myForm.find("#Inscription_Coordonnees_Nom"),
			prenom: myForm.find("#Inscription_Coordonnees_Prenom"),
			cp: myForm.find("#Inscription_Coordonnees_CodePostal"),
			ville: myForm.find("#Inscription_Coordonnees_Ville"),
			pays: myForm.find("#Inscription_Coordonnees_Pays"),
			email: myForm.find("#Inscription_Coordonnees_Email"),
			nbParticipants: myForm.find("#nbParticipants"),
			idEvenement: myForm.find("#Inscription_Coordonnees_IdEvent")
		};

		if ($('#formInscriptionEvent').parsley().isValid() && erreurMail == "") {
			let data = {
				__RequestVerificationToken: token,
				civilite: myData.civilite.val(),
				nom: myData.nom.val(),
				prenom: myData.prenom.val(),
				cp: myData.cp.val(),
				ville: myData.ville.val(),
				pays: myData.pays.val(),
				email: myData.email.val(),
				nbParticipants: myData.nbParticipants.val(),
				idEvenement: myData.idEvenement.val()
			};

			$.ajax({
				url: '/EspritTerdav/SetInscription',
				dataType: 'json',
				type: "POST",
				data: data,
				success: function (data) {
					$('#Ereur_Email').html("");

					if (data.message == "EmailInvalide") {
						myForm.find(".form__message_success").html('');
						$('#Ereur_Email').html("l'email est invalide");
					} else {
						myForm.find(".form__message_error").html('');
						myForm.find(".form__message_success").html(data.message);
						setTimeout(function () {
							history.pushState(null, null, ' ');
							$('#modalEvenement').find('.modal').removeClass('modal_open');
							$('html').css('overflow', 'auto');
						}, 3000);
					}

				},
				error: function (data) {
					myForm.find(".form__message_success").html('');
					myForm.find(".form__message_error").html("Une erreur est survenue.");
				}
			});


		}
	},

	formEvenements: function (ev) {
		ev.preventDefault();

		var myForm = $('#formEvenements');

		var myData = {
			urlForm: myForm.attr('data-url-action'),
			email: myForm.find('#email'),
			refAgence: myForm.find("#refAgence"),
			listCheck: '1-TDV-true'
		};
		var token = $('input[name="__RequestVerificationToken"]', myForm).val();


		let data = {
			__RequestVerificationToken: token,
			email: myData.email.val(),
			refAgence: myData.refAgence.val(),
			listCheck: myData.listCheck
		}

		if (myForm.parsley().isValid()) {
			$.ajax({
				url: myData.urlForm,
				data: data,
				type: "POST",
				success: function (data) {
					myForm.find(".form__message_error").html('');
					myForm.find(".form__message_success").html(data.Message);
					setTimeout(function () {
						$('.form__message').html('');
					}, 3000);
				},
				error: function (data) {
					myForm.find(".form__message_success").html('');
					myForm.find(".form__message_error").html(data.MessageError);
				}
			});
		}
	},

	formPresse: function () {

		var myForm = $('#formPresse');

		var myData = {
			urlForm: myForm.attr('data-url-contact'),
			civilite: myForm.find("#Presse_Coordonnees_Civilite > option:selected"),
			nom: myForm.find('#Presse_Coordonnees_Nom'),
			prenom: myForm.find('#Presse_Coordonnees_Prenom'),
			email: myForm.find('#Presse_Coordonnees_Email'),
			telephonePro: myForm.find('#Presse_Coordonnees_TelephonePro'),
			sujet: myForm.find('#Presse_Coordonnees_Sujet'),
			message: myForm.find('#Presse_Coordonnees_Message'),
			contactPress: document.getElementById('contactPress')
		};

		var token = $('input[name="__RequestVerificationToken"]', myForm).val();

		if (myForm.parsley().isValid()) {

			let data = {
				__RequestVerificationToken: token,
				civilite: myData.civilite.val(),
				nom: myData.nom.val(),
				prenom: myData.prenom.val(),
				email: myData.email.val(),
				telephonePro: myData.telephonePro.val(),
				sujet: myData.sujet.val(),
				message: myData.message.val(),
				contactPress: myData.contactPress.value,
			}

			$.ajax({
				url: myData.urlForm,
				data: data,
				type: "POST",
				success: function (data) {
					myForm.find(".form__message_error").html('');
					myForm.find(".form__message_success").html(data.Message);
					setTimeout(function () {
						$('#modalPresse').removeClass('modal_open');
						$('html').css('overflow', 'auto');
					}, 3000);

				},
				error: function (data) {
					myForm.find(".form__message_success").html('');
					myForm.find(".form__message_error").html(data.MessageError);
				}
			});

		}
	},

	visuVille: function (RtnData) {
		var _listeVilleChoix = [];
		var repville = eval('(' + RtnData + ')');

		var pos = 1;
		while (true) {
			try {
				_listeVilleChoix.push({
					label: repville[pos].Localite + ' ' + repville[pos].LieuDit,
					value: repville[pos].Localite,
					id: pos,
					codeville: repville[pos].IDLocalite,
					ville: repville[pos].Localite,
					lieudit: repville[pos].LieuDit,
					cp: repville[pos].CodePostal
				});
				pos += 1;
			} catch (err) {

				break;
			}
		}

		pos -= 1;
		$('form').find('.Ville').focus();
		if (pos > 1) {

			$('form').find('.Ville').autocomplete({
				options: {
					delay: 1,
					minLength: 3
				},
				source: _listeVilleChoix

			}).autocomplete("search", " ");
		} else {
			if (pos == 1) {
				$('form').find('.Ville').val(_listeVilleChoix[0]['ville']);
			}
		}

	},

	valideCP: function (myForm) {
		var regexCp = /[0-9{4,5}]/; //regex FRA, BEL, CHE
		var pays = myForm.find(".Pays").val();

		if ((pays == "FRA" || pays == "BEL" || pays == "CHE") && !regexCp.test(myForm.find(".CodePostal").val())) {
			return false;
		}
		return true;
	},

	afficheVille: function () {
		var _licence = 'TERDAV';
		var _instance = 0;
		var _urldqe = 'https://prod3.dqe-software.com/'

		var myForm = $(this).closest('form');

		if (form.valideCP(myForm)) {
			$.ajax({
				url: _urldqe + "CP/",
				dataType: "jsonp",
				cache: false,
				data: {
					Pays: myForm.find(".Pays").val(),
					CodePostal: myForm.find(".CodePostal").val(),
					Licence: _licence,
					Instance: _instance,
					Alpha: false
				},
				jsonp: "callback",
				crossDomain: true,
				success: form.visuVille
			});
		}
	},

	init: function () {
		form.event();
	}
};

function verifMail(champ) {
	var regex = /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;

	if (!regex.test(champ)) {
		return false;
	} else {
		return true;
	}
}